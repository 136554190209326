import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import MainRoute from "containers/SiteRoute/Main";

export default () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to={'/main/home/'} />}></Route>
            <Route path="/main/*" element={<MainRoute />}></Route>
        </Routes>
    )
}